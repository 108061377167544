.IntroText-pic {
  background-image: url('/expo.jpg');
  height: 0;
  width:100%;
  padding-bottom: 50%;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
}

.IntroText {
  color: #333;
  margin-top: 5%;
  margin-bottom: 10%;
}

.IntroText h1 {
  font-size: 3vw;
  /*text-align: center;*/
}


@media only screen and (max-width: 640px),
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .IntroText h1 {
    font-size: 6vw;
    /*text-align: center;*/
  }

  .IntroText {
    margin-bottom: 10%;
  }
}
