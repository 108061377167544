@font-face {
  font-family: 'FiraSans-Light';
  src: url('/FiraSans-Light.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url('/FiraSans-Light.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

* {
  font-family: 'FiraSans-Light';
}

p {
  margin-top: 1.5em;
}

section {
  clear: both;
}

.App-info {
  width: 50%;
  margin:0 auto;
}

.App-status {
  width: 100%;
  margin:0 auto;
  margin-top: 10%;
  margin-bottom: 10%;
  text-align: right;
}

@media only screen and (max-width: 640px),
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .App-info {
    width: 100%;
    margin:0 auto;
  }
  .App-status {
    width: 100%;
    margin:0 auto;
    font-size: 0.5em;
  }
}

/* */
.App-upper {
  background-color: white;
  /*padding: 50px;*/
}

/* */
.App-middle {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}

/* */
.App-lower {
  background-color: white;
  /*padding: 50px;*/
}

.App-ready {
  width:7.5%;
  height: 0;
  padding-top: 7.5%;
  background-image: url('/ready.svg'), radial-gradient(#333 0%,#aaa 200%);
  background-size: 90%;
  background-position: center;
  border-radius: 10%;
  transform: rotateY(0deg) rotate(45deg);
  margin-left: 46.25%;
  margin-top: 2.5%;
  box-sizing: border-box;
}

.App-ready:hover {
  width:8%;
  padding-top: 8%;
  margin-left: 46%;
  margin-top: 2%;
}

.App-ready-text {
  margin-top: 2.5%;
  text-align: center;
  font-size: 0.75em;
}

strong {
  font-size: 1.5em;
  font-style: normal;
}

@media only screen and (max-width: 640px),
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .App-upper {
    background-color: white;
    padding: 10%;
  }
}
