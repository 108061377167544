/* keypad */
.Pad16-pad {
  --scale: 1.41; /* √2 (rotating it makes it width and height √2 times bigger) */
  --size: 65.0; /* percent */
  --rsize: calc(var(--scale) * var(--size));
  --center: calc(50% - var(--size) * 0.5%);
  --min: calc((var(--size) * (-0.5 + var(--scale) * 0.5)) * 1%);
  --max: calc((100 - var(--size) * (0.5 + var(--scale) * 0.5)) * 1%);
  position: absolute;
  transform: rotateY(0deg) rotate(45deg);
  width: calc(var(--size) * 1%);
  height: calc(var(--size) * 1%);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.center {
  left:var(--center);
  top:var(--center);
}

.up {
  left: var(--center);
  top: var(--min);
}

.left {
  left: var(--min);
  top: var(--center);
}

.right {
  left: var(--max);
  top: var(--center);
}

.down {
  left: var(--center);
  top: var(--max);
}

.row {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.button {
  width: 20%;
  height: 100%;
  box-sizing: border-box;
  border: 0px solid #888;
  /*border:1px solid black;*/
  border-radius: 15%;
  transition: background-color 0.5s ease-in, border-width 0.3s ease, box-shadow 0.3s ease-in;
  /*border-style: inset;*/
}

.button:hover:not(.playing) {
  /*border-top: 3px solid #888;
  border-left: 3px solid #888;*/
  border: 3px solid #888;
  /*border-style: inset;*/
}

/*
.orange.lit {
  background-color: hsl(40, 100%, 50%);
  box-shadow: 0px 0px 1px 1px hsl(40, 100%, 80%);
}

.orange.dim {
  background-color: hsl(40, 100%, 95%);
}

.yellow.lit {
  background-color: hsl(60, 100%, 50%);
  box-shadow: 0px 0px 1px 1px hsl(60, 100%, 50%);
}

.yellow.dim {
  background-color: hsl(60, 100%, 95%);
}

.green.lit {
  background-color: hsl(144, 100%, 50%);
  box-shadow: 0px 0px 1px 1px hsl(144, 100%, 50%);
}

.green.dim {
  background-color: hsl(144, 100%, 95%);
}

.lightblue.lit {
  background-color: hsl(180, 100%, 50%);
  box-shadow: 0px 0px 1px 1px hsl(180, 100%, 50%);
}

.lightblue.dim {
  background-color: hsl(180, 100%, 95%);
}

.blue.lit {
  background-color: hsl(204, 100%, 50%);
  box-shadow: 0px 0px 1px 1px hsl(204, 100%, 50%);
}

.blue.dim {
  background-color: hsl(204, 100%, 95%);
}

.pink.lit {
  background-color: hsl(321, 100%, 65%);
  box-shadow: 0px 0px 1px 1px hsl(321, 100%, 65%);
}

.pink.dim {
  background-color: hsl(321, 100%, 95%);
}
*/

.orange.playing,
.orange.on {
  background-image:radial-gradient(hsl(40, 100%, 50%) 0%, #aaa 400%);
}

.orange.playing.playing-1 {
  background-position: center;
  box-shadow: 0px 0px 3px 3px hsl(40, 100%, 70%);
}

.orange.off {
  background-image:radial-gradient(hsl(40, 60%, 50%) 0%, #aaa 50%);
}

.yellow.playing,
.yellow.on {
  background-image:radial-gradient(hsl(60, 100%, 50%) 0%, #aaa 400%);
}

.yellow.playing.playing-1 {
  box-shadow: 0px 0px 3px 3px hsl(60, 100%, 70%);
}

.yellow.off {
  background-image:radial-gradient(hsl(60, 60%, 50%) 0%, #aaa 50%);
}

.green.playing,
.green.on {
  background-image:radial-gradient(hsl(144, 100%, 50%) 0%, #aaa 400%);
}

.green.playing-1 {
  box-shadow: 0px 0px 3px 3px hsl(144, 100%, 70%);
}

.green.off {
  background-image:radial-gradient(hsl(144, 60%, 50%) 0%, #aaa 50%);
}

.lightblue.playing,
.lightblue.on {
  background-image:radial-gradient(hsl(180, 100%, 50%) 0%, #aaa 400%);
}

.lightblue.playing-1 {
  box-shadow: 0px 0px 3px 3px hsl(180, 100%, 70%);
}

.lightblue.off {
  background-image:radial-gradient(hsl(180, 60%, 50%) 0%, #aaa 50%);
}

.blue.playing,
.blue.on {
  background-image:radial-gradient(hsl(204, 100%, 50%) 0%, #aaa 400%);
}

.blue.playing-1 {
  box-shadow: 0px 0px 3px 3px hsl(204, 100%, 70%);
}

.blue.off {
  background-image:radial-gradient(hsl(204, 60%, 50%) 0%, #aaa 50%);
}

.pink.playing,
.pink.on {
  background-image:radial-gradient(hsl(321, 100%, 65%) 0%, #aaa 400%);
}

.pink.playing-1 {
  box-shadow: 0px 0px 3px 3px hsl(321, 100%, 80%);
}

.pink.off {
  background-image:radial-gradient(hsl(321, 60%, 65%) 0%, #aaa 50%);
}

.purple.playing,
.purple.on {
  background-image:radial-gradient(hsl(280, 100%, 65%) 0%, #aaa 400%);
}

.purple.playing-1 {
  box-shadow: 0px 0px 3px 3px hsl(280, 100%, 80%);
}

.purple.off {
  background-image:radial-gradient(hsl(280, 60%, 65%) 0%, #aaa 50%);
}
