/* container its width defines width of interface, height adapts to size of content */
.LaunchPad-container {
  margin: 0 auto;
  position: relative;
  width: 100%;
  max-width: 1600px;
}

/* rectangle 2:1 aspect ration elements, relative to container width */
.LaunchPad-element {
  width: 50%;
  overflow: hidden;
  height: 0;
  padding-top: 25%; /*aspect ratio 2:1*/
  position: relative;
  float:left;
}

.LaunchPad-element-col {
  position: absolute;
  top:0;
  width:50%;
  height:100%;
}

.LaunchPad-element-0 .LaunchPad-element-col-1,
.LaunchPad-element-2 .LaunchPad-element-col-1 {
  left:50%;
}

.LaunchPad-element-1 .LaunchPad-element-col-0,
.LaunchPad-element-3 .LaunchPad-element-col-0 {
  left:50%;
}

.LaunchPad-element-image {
  width: 80%;
  height: 80%;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  margin-top: 10%;
  margin-left: 10%;
}

.LaunchPad-element-button {
  width: 30%;
  height: 30%;
  background: white;
  position: absolute;
  top: 35%;
  border-radius: 50%;
  box-sizing: border-box;
  transition: border-width 0.1s ease, border-color 0.1s ease;
  border:1px solid #888;
  transform: rotateY(0deg) rotate(45deg);
}

.LaunchPad-element-button:hover:not(.playing) {
  border:3px solid #888;
}

.LaunchPad-element-0 .LaunchPad-element-button,
.LaunchPad-element-2 .LaunchPad-element-button {
  left:0%;
}

.LaunchPad-element-1 .LaunchPad-element-button,
.LaunchPad-element-3 .LaunchPad-element-button {
  right:0%;
}

.LaunchPad-element-image,
.LaunchPad-element-button {
  /*background-color: lightgray;*/
}

.LaunchPad-element-0 .LaunchPad-element-image  {
  background-image: url('/img-obj-1.jpg');
}

.LaunchPad-element-0 .LaunchPad-element-button  {
  background-image: url('/play-obj-1.svg');
}

.LaunchPad-element-0 .LaunchPad-element-button.playing  {
  background-image: url('/play-obj-1.svg'), radial-gradient(#fff 0%, hsl(50, 100%, 50%) 90%);;
}

.LaunchPad-element-1 .LaunchPad-element-image  {
  background-image: url('/img-obj-2.jpg');
}

.LaunchPad-element-1 .LaunchPad-element-button  {
  background-image: url('/play-obj-2.svg');
}

.LaunchPad-element-1 .LaunchPad-element-button.playing  {
  background-image: url('/play-obj-2.svg'), radial-gradient(#fff 0%, hsl(50, 100%, 50%) 90%);;
}

.LaunchPad-element-2 .LaunchPad-element-image  {
  background-image: url('/img-obj-3.jpg');
}

.LaunchPad-element-2 .LaunchPad-element-button  {
  background-image: url('/play-obj-3.svg');
}

.LaunchPad-element-2 .LaunchPad-element-button.playing  {
  background-image: url('/play-obj-3.svg'), radial-gradient(#fff 0%, hsl(50, 100%, 50%) 90%);;
}

.LaunchPad-element-3 .LaunchPad-element-image  {
  background-image: url('/img-obj-4.jpg');
}

.LaunchPad-element-3 .LaunchPad-element-button  {
  background-image: url('/play-obj-4.svg');
}

.LaunchPad-element-3 .LaunchPad-element-button.playing  {
  background-image: url('/play-obj-4.svg'), radial-gradient(#fff 0%, hsl(50, 100%, 50%) 90%);
}

.LaunchPad-element-button.playing-1 {
  box-shadow: 0px 0px 3px 3px hsl(50, 100%, 70%);
  transition: box-shadow 0.5s ease-in
}

.LaunchPad-help {
  border-radius: 50%;
  position: absolute;
  height: 0;
  width: 4%;
  padding-top: 4%;
  overflow: hidden;
  left: 48%;
  top: 46%;
  background-image: url('/help.svg'), radial-gradient(#333 0%,#aaa 200%);
}

.LaunchPad-help.playing  {
  background-image: url('/help.svg'), radial-gradient(#fff 0%, hsl(50, 100%, 50%) 90%);;
}

.LaunchPad-help.playing-1 {
  box-shadow: 0px 0px 3px 3px hsl(50, 100%, 70%);
  transition: border-width 0.1s ease, border-color 0.1s ease;
}

.LaunchPad-help:hover:not(.playing) {
  background-size: 95%;
  background-position: center;
}

/* wide viewport, use viewport height to maximize container width*/
@media (min-aspect-ratio: 8/4)  {
  .LaunchPad-container.fullscreen {
    width: 100vh;
    max-width : none;
  }
  .LaunchPad-container {
    width: 200vh;
    max-width : max(100vh 1200px);
  }
}

/* small screens make interface width 100% of container width*/
@media only screen and (max-width: 640px),
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .LaunchPad-element {
    width: 100%;
    padding-top: 50%;
  }
  .center-button {
    margin-top: 0%;
    margin-left: 35%;
    width:30%;
    padding-top: 30%;
    float: left;
    position: relative;
  }
  .LaunchPad-help {
    width:8%;
    padding-top: 8%;
    left:46%;
    top:48%;
    /*top:0%;*/
    overflow: hidden;
  }
}

.LaunchPad-fullscreen {
  position: absolute;
  top: -5%;
  right: 1.5%;
  background-image: url('/fullscreen.svg');
  background-size: contain;
  display: inline-block;
  width: 2.5%;
  height: 5%;
  background-repeat: no-repeat;
  background-position: center;
}

.LaunchPad-fullscreen:hover {
  width: 2.6%;
  height: 5.2%;
}
